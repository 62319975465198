var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mx-auto justify-content-center"},[(_vm.step === 1)?_c('ValidationObserver',{staticClass:"col-lg-12 pt-3"},[_c('el-collapse-transition',[_c('div',{staticClass:"col-12 pl-0 pl-md-4 px-0 pr-0 mt-n2 row mx-auto align-items-start justify-content-center py-2 flex-nowrap"},[(_vm.mobile)?_c('iframe',{attrs:{"id":"aa0f76a8","name":"aa0f76a8","src":"https://ads.nexoinmobiliario.pe/www/delivery/afr.php?refresh=6&zoneid=60&source=_&target=_blank&cb=INSERT_RANDOM_NUMBER_HERE","frameborder":"0","scrolling":"no","width":"300","height":"75","allowtransparency":"true"}},[_c('a',{attrs:{"href":"https://ads.nexoinmobiliario.pe/www/delivery/ck.php?n=aa5fb8b6&cb=INSERT_RANDOM_NUMBER_HERE","target":"_blank"}},[_c('img',{attrs:{"src":"https://ads.nexoinmobiliario.pe/www/delivery/avw.php?zoneid=60&source=_&cb=INSERT_RANDOM_NUMBER_HERE&n=aa5fb8b6","border":"0","alt":""}})])]):_c('iframe',{attrs:{"id":"aa002f89","name":"aa002f89","src":"https://ads.nexoinmobiliario.pe/www/delivery/afr.php?refresh=6&zoneid=59&source=_&target=_blank&cb=INSERT_RANDOM_NUMBER_HERE","frameborder":"0","scrolling":"no","width":"850","height":"75","allowtransparency":"true"}},[_c('a',{attrs:{"href":"https://ads.nexoinmobiliario.pe/www/delivery/ck.php?n=abc02715&cb=INSERT_RANDOM_NUMBER_HERE","target":"_blank"}},[_c('img',{attrs:{"src":"https://ads.nexoinmobiliario.pe/www/delivery/avw.php?zoneid=59&source=_&cb=INSERT_RANDOM_NUMBER_HERE&n=abc02715","border":"0","alt":""}})])])])])],1):_vm._e(),_vm._m(0),_c('ValidationObserver',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 1),expression:"step === 1"}],staticClass:"col-lg-6 col-xl-4",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.firstStep)}}},[_c('h4',{staticClass:"text-center my-0 text-white"},[_vm._v(" Registro a la capacitación Nexo Agentes ")]),_c('p',{staticClass:"text-white f-14 text-center"},[_vm._v(" Para registrarse a la capacitación Nexo Agentes, deberás llenar la siguiente información: ")]),(_vm.error)?_c('p',{staticClass:"bg-danger text-white f-13 p-1 text-center"},[_vm._v(" El código MVCS no corresponde al DNI ingresado. ")]):_vm._e(),_c('ValidationProvider',{staticClass:"mb-2 line-height-18",attrs:{"name":"correo electrónico","rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors[0])?_c('span',{staticClass:"text-white ml-3 f-13 bg-danger px-2 mb-n2"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('el-input',{staticClass:"rounded-pill",attrs:{"placeholder":"Ingrese su correo electrónico","type":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"mb-2 line-height-18",attrs:{"name":"nombres","tag":"div","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors[0])?_c('span',{staticClass:"text-white ml-3 f-13 bg-danger px-2 mb-n2"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('el-input',{staticClass:"rounded-pill",attrs:{"placeholder":"Ingrese sus nombres"},model:{value:(_vm.form.nombres),callback:function ($$v) {_vm.$set(_vm.form, "nombres", $$v)},expression:"form.nombres"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"mb-2 line-height-18",attrs:{"name":"apellido paterno","rules":"required|alpha_spaces","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors[0])?_c('span',{staticClass:"text-white ml-3 f-13 bg-danger px-2 mb-n2"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('el-input',{staticClass:"rounded-pill",attrs:{"placeholder":"Ingrese su apellido paterno"},model:{value:(_vm.form.apellidoPaterno),callback:function ($$v) {_vm.$set(_vm.form, "apellidoPaterno", $$v)},expression:"form.apellidoPaterno"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"mb-2 line-height-18",attrs:{"name":"apellido materno","rules":"required|alpha_spaces","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors[0])?_c('span',{staticClass:"text-white ml-3 f-13 bg-danger px-2 mb-n2"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('el-input',{staticClass:"rounded-pill",attrs:{"placeholder":"Ingrese su apellido materno"},model:{value:(_vm.form.apellidoMaterno),callback:function ($$v) {_vm.$set(_vm.form, "apellidoMaterno", $$v)},expression:"form.apellidoMaterno"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"mb-2 line-height-18",attrs:{"name":"número de celular","rules":"required|numeric|min:9|max:9","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors[0])?_c('span',{staticClass:"text-white ml-3 f-13 bg-danger px-2 mb-n2"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('el-input',{staticClass:"rounded-pill",attrs:{"placeholder":"Ingrese su número de celular","maxlength":9},model:{value:(_vm.form.celular),callback:function ($$v) {_vm.$set(_vm.form, "celular", $$v)},expression:"form.celular"}})]}}],null,true)}),_c('div',{staticClass:"mb-2 d-flex align-items-end"},[_c('el-select',{staticClass:"rounded-pill col-3 pl-0 pr-2",on:{"change":_vm.handleDocumentType},model:{value:(_vm.form.typoDoc),callback:function ($$v) {_vm.$set(_vm.form, "typoDoc", $$v)},expression:"form.typoDoc"}},_vm._l((_vm.documents),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('ValidationProvider',{staticClass:"line-height-18 w-100",attrs:{"name":"dni","tag":"div","rules":{
                        required: true,
                        min: _vm.minlengthDocument
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [(errors[0])?_c('span',{staticClass:"text-white ml-3 f-13 bg-danger px-2 mb-n2"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('el-input',{staticClass:"rounded-pill",attrs:{"placeholder":"Ingrese el numero de documento","maxlength":_vm.maxlengthDocument},on:{"input":_vm.handleDocumentNumber},model:{value:(_vm.form.nroDoc),callback:function ($$v) {_vm.$set(_vm.form, "nroDoc", $$v)},expression:"form.nroDoc"}})]}}],null,true)})],1),_c('ValidationProvider',{ref:"mvcs",staticClass:"mb-2 line-height-18",attrs:{"name":"mvcs","rules":"required|numeric","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [(errors[0])?_c('span',{staticClass:"text-white ml-3 f-13 bg-danger px-2 mb-n2"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('el-input',{staticClass:"rounded-pill hide-arrows",attrs:{"type":"number","placeholder":"Ingrese su N° código PN MVCS -  Ej: 1250"},model:{value:(_vm.form.codigoMVCS),callback:function ($$v) {_vm.$set(_vm.form, "codigoMVCS", $$v)},expression:"form.codigoMVCS"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"mb-2 line-height-18 pt-3 pb-2",attrs:{"rules":{ required: { allowFalse: false } },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('el-checkbox',{staticClass:"text-white white-space-normal d-flex",model:{value:(_vm.form.terms),callback:function ($$v) {_vm.$set(_vm.form, "terms", $$v)},expression:"form.terms"}},[_c('div',{staticClass:"text-white"},[_vm._v(" Acepto "),_c('a',{staticClass:"font-weight-bold text-white",attrs:{"href":"/pdf/terminos_y_condiciones_nexo_agentes.pdf","target":"_blank"}},[_vm._v("Términos y Condiciones")]),_vm._v(" y autorizo el uso de mis datos de acuerdo a la "),_c('a',{staticClass:"font-weight-bold text-white",attrs:{"href":"/pdf/privacidad.pdf","target":"_blank"}},[_vm._v("Política de Privacidad y Cookies")])])]),(errors[0])?_c('span',{staticClass:"text-white ml-3 f-13 bg-danger px-2 mb-n2"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"d-flex justify-content-center pt-4"},[_c('el-button',{staticClass:"px-5",attrs:{"native-type":"submit","type":"warning","round":""}},[_vm._v("Registrarme")])],1),_c('p',{staticClass:"text-white f-13 text-center"},[_vm._v(" Ya me registré.. "),_c('router-link',{staticClass:"text-white font-weight-bold",attrs:{"to":{ name: 'Login' }}},[_vm._v("Quiero acceder a mi cuenta")])],1),_c('p',{staticClass:"text-white f-13 text-center"},[_vm._v(" Cualquier consulta o duda comunícate con nosotros enviando un correo electrónico a info@nexoagentes.pe ")])],1)]}}])}),([2, 3].includes(_vm.step))?_c('form-second-step',{staticClass:"col-lg-6 col-xl-5 pt-2",attrs:{"form":_vm.form,"step":_vm.step},on:{"update:form":function($event){_vm.form=$event},"update:step":function($event){_vm.step=$event}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 mx-auto justify-content-center pt-4 d-flex"},[_c('img',{staticClass:"logotipo my-4",attrs:{"src":require("@/assets/img/logotipo-nexoagentes-b.png"),"alt":"register"}})])}]

export { render, staticRenderFns }