




















































































































































































































































































































import { Component, Mixins, Ref } from 'vue-property-decorator';
import { agentModule } from '@/store/modules/agent';
import { User } from '@/interfaces/user';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import FormSecondStep from './FormSecondStep.vue';
import DocumentMixin from '@/mixins/document';
import ResizeMixin from '@/mixins/size';

@Component({
    components: { FormSecondStep }
})
export default class Home extends Mixins(DocumentMixin, ResizeMixin) {
    mobile = true;
    mounted() {
        this.resize();
    }
    resize() {
        this.mobile = window.innerWidth <= 992;
    }

    @Ref() mvcs!: InstanceType<typeof ValidationProvider>;
    form: Partial<User> & {
        code?: string;
        training?: string;
        document?: File | string;
        filename?: string;
    } = {
        typoDoc: 'dni'
    };
    step = 1;
    error = '';
    minlengthDocument = 8;

    documents = [
        {
            value: 'dni',
            label: 'DNI'
        },
        {
            value: 'pas',
            label: 'PAS'
        },
        {
            value: 'ce',
            label: 'C.E.'
        }
    ];

    async firstStep() {
        try {
            await agentModule.registrationFirstStep(this.form);
            this.step++;
        } catch (error) {
            this.$notify.error({
                title: 'Error',
                message:
                    typeof error.mensaje === 'string'
                        ? error.mensaje
                        : 'Hemos tenido algunos inconvenientes'
            });
        }
    }

    handleDocumentType(value: string): void {
        this.maxlengthDocument = value === 'dni' ? 8 : 200;
        this.minlengthDocument = value === 'dni' ? 8 : 7;
        this.form.nroDoc = '';
    }

    handleDocumentNumber(value: string) {
        this.form.nroDoc = this.changeDocumentNumber({
            value,
            maxlengthDocument: this.maxlengthDocument
        });
    }
}
